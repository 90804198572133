import { defineNuxtPlugin } from '#app/nuxt'
import { LazyACircularMenuAccountMenu, LazyACircularMenuActivity, LazyASubsidebarAccount, LazyASubsidebarBank, LazyASubsidebarContact, LazyASubsidebarDashboards, LazyASubsidebarExpense, LazyASubsidebarItem, LazyASubsidebarJournal, LazyASubsidebarLayouts, LazyASubsidebarPayment, LazyASubsidebarPurchase, LazyASubsidebarSale, LazyASubsidebarTax, LazyAThemeToggle, LazyAToolbarAccountMenu, LazyAToolbarActivity, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ACircularMenuAccountMenu", LazyACircularMenuAccountMenu],
["ACircularMenuActivity", LazyACircularMenuActivity],
["ASubsidebarAccount", LazyASubsidebarAccount],
["ASubsidebarBank", LazyASubsidebarBank],
["ASubsidebarContact", LazyASubsidebarContact],
["ASubsidebarDashboards", LazyASubsidebarDashboards],
["ASubsidebarExpense", LazyASubsidebarExpense],
["ASubsidebarItem", LazyASubsidebarItem],
["ASubsidebarJournal", LazyASubsidebarJournal],
["ASubsidebarLayouts", LazyASubsidebarLayouts],
["ASubsidebarPayment", LazyASubsidebarPayment],
["ASubsidebarPurchase", LazyASubsidebarPurchase],
["ASubsidebarSale", LazyASubsidebarSale],
["ASubsidebarTax", LazyASubsidebarTax],
["AThemeToggle", LazyAThemeToggle],
["AToolbarAccountMenu", LazyAToolbarAccountMenu],
["AToolbarActivity", LazyAToolbarActivity],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
