export default defineAppConfig({
  nuxtIcon: {},
  tairo: {
    title: 'Accomium',
    sidebar: {
      enabled: true,
      toolbar: {
        showNavBurger: true,
        tools: [
          {
            component: 'AThemeToggle',
            props: {
              disableTransitions: true,
            },
          },
          {
            component: 'AToolbarActivity',
          },
          {
            component: 'AToolbarAccountMenu',
          },
        ],
      },
      circularMenu: {
        enabled: true,
        tools: [
          {
            component: 'AThemeToggle',
            props: {
              class: 'ms-auto',
              disableTransitions: true,
              inverted: true,
            },
          },
          {
            component: 'ACircularMenuActivity',
          },
          {
            component: 'ACircularMenuAccountMenu',
          },
        ],
      },
      navigation: {
        enabled: true,
        logo: {
          component: 'TairoLogo',
          props: { class: 'text-primary-600 h-10' },
        },
        items: [
          {
            title: 'Account',
            icon: { name: 'ph:tire-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarAccount' },
            activePath: '/account',
          },
          {
            title: 'Bank',
            icon: { name: 'ph:bank-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarBank' },
            activePath: '/bank',
          },
          {
            title: 'Item',
            icon: { name: 'ph:tractor-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarItem' },
            activePath: '/item',
          },
          {
            title: 'Contact',
            icon: { name: 'ph:user-circle-plus-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarContact' },
            activePath: '/contact',
          },

          {
            title: 'Tax',
            icon: { name: 'ph:bird-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarTax' },
            activePath: '/tax',
          },
          {
            title: 'Journal',
            icon: { name: 'ph:traffic-cone-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarJournal' },
            activePath: '/journal',
          },
          {
            title: 'Payment',
            icon: { name: 'ph:wallet-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarPayment' },
            activePath: '/payment',
          },
          {
            title: 'Expense',
            icon: { name: 'ph:bathtub-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarExpense' },
            activePath: '/expense',
          },
          {
            title: 'Sale',
            icon: { name: 'ph:sunglasses-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarSale' },
            activePath: '/sale',
          },
          {
            title: 'Purchase',
            icon: { name: 'ph:suitcase-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'ASubsidebarPurchase' },
            activePath: '/purchase',
          },
          {
            title: 'Setting',
            icon: { name: 'ph:gear-six-duotone', class: 'w-5 h-5' },
            to: '/setting/list',
            position: 'end',
          },
        ],
      },
      admin: {
        logo: {
          component: 'TairoLogo',
          props: { class: 'text-primary-600 h-10' },
        },
        items: [
          {
            title: 'Company',
            icon: { name: 'ph:balloon-duotone', class: 'w-5 h-5' },
            // subsidebar: { component: 'ASubsidebarAccount' },
            activePath: '/admin/company',
          },
          {
            title: 'Cost',
            icon: { name: 'ph:basketball-duotone', class: 'w-5 h-5' },
            // subsidebar: { component: 'ASubsidebarBank' },
            activePath: '/admin/cost',
          },
          {
            title: 'Service',
            icon: { name: 'ph:airplane-duotone', class: 'w-5 h-5' },
            // subsidebar: { component: 'ASubsidebarItem' },
            activePath: '/admin/service',
          },
        ],
      },
    },
    //the below is for landing page topnav
    topnav: {
      navigation: {
        enabled: true,
        logo: {
          component: 'TairoLogo',
          props: { class: 'text-primary-600 h-10 w-10' },
        },
        header: {
          component: 'ATopnavWorkspaceDropdown',
        },
      },
      circularMenu: {
        enabled: true,
        tools: [
          {
            component: 'AThemeToggle',
            props: {
              class: 'ms-auto',
              disableTransitions: true,
              inverted: true,
            },
          },
          {
            component: 'ACircularMenuLanguage',
          },
          // {
          //   component: 'ACircularMenuNotifications',
          // },
          {
            component: 'ACircularMenuActivity',
          },
        ],
      },
      toolbar: {
        enabled: true,
        showTitle: false,
        tools: [
          {
            component: 'AThemeToggle',
            props: {
              disableTransitions: true,
            },
          },
          {
            component: 'AToolbarSearch',
          },
          {
            component: 'AToolbarCustomize',
          },
          {
            component: 'AToolbarNotifications',
          },
          {
            component: 'AAccountMenu',
            props: {
              horizontal: true,
            },
          },
        ],
      },
    },
    error: {
      logo: {
        component: 'img',
        props: {
          src: '/img/illustrations/system/404-1.svg',
          class: 'relative z-20 w-full max-w-lg mx-auto',
        },
      },
    },
  },
})

