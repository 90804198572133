import revive_payload_client_F5JUyu4kq1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0jW0njCamp from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_veESh1iiRC from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NX85Arx6F2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_SaRWgQK9f2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_KSmy29qgWn from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.app/.nuxt/components.plugin.mjs";
import prefetch_client_EC4pUdPAvj from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_exNVq2O2Nn from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_8ZQh7hY0br from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.4.21/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_client_eT8PmIXZfS from "/vercel/path0/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_44TtN2Tvnn from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_9wgVyZCgNR from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.1_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/.app/plugins/analytics.client.ts";
import google_recaptcha_rYK9Kziv68 from "/vercel/path0/.app/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_F5JUyu4kq1,
  unhead_0jW0njCamp,
  router_veESh1iiRC,
  payload_client_NX85Arx6F2,
  check_outdated_build_client_SaRWgQK9f2,
  plugin_vue3_KSmy29qgWn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EC4pUdPAvj,
  plugin_client_exNVq2O2Nn,
  plugin_8ZQh7hY0br,
  plugin_client_eT8PmIXZfS,
  plugin_44TtN2Tvnn,
  chunk_reload_client_9wgVyZCgNR,
  directives_yBPzJQtdia,
  analytics_client_mtqVw3YRXk,
  google_recaptcha_rYK9Kziv68
]